import React, { useEffect, useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";
import CategoryItem from "../CategoryItem";
import {
  Container,
  Content,
  ViewText,
  ViewTextInput,
  ViewButton,
  ViewOrder,
  ContentIcon,
  ViewIcon
} from "./style";
import {
  getDocs,
  collection,
  setDoc,
  deleteDoc,
  doc,
  orderBy,
  query,
} from "firebase/firestore";
import { firestore } from "../../services/firebase";
import THEME from "../../config/theme";
import ViewPortProvider from "../../hooks/ViewPortProvider";
import useViewport from "../../hooks/useViewport";
import { Octicons, Feather, MaterialIcons } from "@expo/vector-icons";
import { FooterText, StandardText, SmallText } from "../../config/theme/globalStyles";
import TextInput from "../TextInput";
import { Provider } from "react-native-paper";
import { AlertBox } from "../AlertBox";
import { CommonActions } from "@react-navigation/native";
import { ActivityIndicator } from "react-native";
import { firestoreAutoId, removeSansSerif } from '../../utils';
import { useCustomTheme } from '../../contexts/useCustomTheme';


const CategoryList = (props) => {
  const {
    category,
    user,
    modalFunction,
    navigation,
    searchTitle,
    cardsResults,
    videosResults,
  } = props;

  const { customColor, customFont } = useCustomTheme();

  const [state, setState] = useState({
    allCards: [],
    scrollX: 0,
    categoryTitle: category?.title,
    categoryOrder: category?.order,
    visible: false,
    isSearchVisible: false,
    editButtonName: "edit",
    isDeleteVisible: false,
    isConfirmationVisible: false,
    isLoading: false,
    visibleAlert: false,
    message: null,
  });

  const showModal = () => {
    setState((prevState) => ({ ...prevState, isDeleteVisible: true }));
  };

  let cardWidth = 340; //largura card 21rem + padding 1rem
  const title = category ? category?.title : searchTitle;

  const findAllCategories = async (isAdmin) => {
    const categoryRef = collection(firestore, `categories/${category?.id}/cards`);
    const q = query(categoryRef, orderBy("order", "asc"));
    const response = await getDocs(q);
    let cards = [];
    response.forEach((doc) => {
      if (isAdmin && !doc.data().hidden || !doc.data().archived) {
        cards.push({ id: doc.id, ...doc.data() });
      }
    });
    setState((prevState) => ({ ...prevState, allCards: cards }));
  };

  useEffect(() => {
    const fetchData = async () => {
      if (category) {
        setTimeout(() => setState((prevState) => ({ ...prevState, isSearchVisible: !prevState.visible })), 2500);
        await findAllCategories(user?.isAdmin);
      } else {
        setTimeout(() => setState((prevState) => ({ ...prevState, isSearchVisible: !prevState.visible })), 2500);
      }
    };

    fetchData();
  }, [category, cardsResults, videosResults]);

  const data = state.allCards?.length > 0 ? state.allCards : (cardsResults || videosResults);

  const MobileOrDesktopLeftArrow = ({ array }) => {
    const { width } = useViewport();
    const breakpoint = 1080;

    return width < breakpoint ? (
      <></>
    ) : (
      <>
        {window.innerWidth <
          (array?.length || state.allCards.length) * cardWidth ? (
          <TouchableOpacity onPress={handleLeftArrow} style={{ zIndex: 99 }}>
            <ViewIcon
              style={{
                left: 0,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Feather
                name="chevron-left"
                color={
                  customColor &&
                    customColor.iconsMain &&
                    customColor.iconsMain.iconLock
                    ? customColor.iconsMain.iconLock
                    : THEME.COLORS.ICON
                }
                size={30}
              />
            </ViewIcon>
          </TouchableOpacity>
        ) : (
          <></>
        )}
      </>
    );
  };

  const MobileOrDesktopRightArrow = ({ array }) => {
    const { width } = useViewport();
    const breakpoint = 1080;

    return width < breakpoint ? (
      <></>
    ) : (
      <>
        {window.innerWidth <
          (array?.length || state.allCards.length) * cardWidth ? (
          <TouchableOpacity
            onPress={() => handleRightArrow(array)}
            style={{ zIndex: 99 }}
          >
            <ViewIcon
              style={{
                right: 0,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Feather
                name="chevron-right"
                color={
                  customColor &&
                    customColor.iconsMain &&
                    customColor.iconsMain.iconLock
                    ? customColor.iconsMain.iconLock
                    : THEME.COLORS.ICON
                }
                size={30}
              />
            </ViewIcon>
          </TouchableOpacity>
        ) : (
          <></>
        )}
      </>
    );
  };

  const handleLeftArrow = () => {
    let scrollPosition = state.scrollX + Math.round(window.innerWidth / 2);
    if (scrollPosition > 0) {
      scrollPosition = 0;
    }
    setState((prevState) => ({ ...prevState, scrollX: scrollPosition }));
  };

  const handleRightArrow = (array) => {
    let scrollPosition = state.scrollX - Math.round(window.innerWidth / 2);
    let listW = array.length * cardWidth;
    if (window.innerWidth > listW) {
      scrollPosition = 0;
    } else if (window.innerWidth - listW > scrollPosition) {
      scrollPosition = window.innerWidth - listW - array.length;
    }
    setState((prevState) => ({ ...prevState, scrollX: scrollPosition }));
  };

  const saveCategories = async () => {
    const categoryRef = doc(firestore, "categories", category?.id);
    await setDoc(
      categoryRef,
      {
        title: state.categoryTitle,
        order: state.categoryOrder,
      },
      { merge: true }
    );
    showAlert("Categoria Editada com sucesso! :)");
  };

  const showAlert = (message) => {
    setState((prevState) => ({ ...prevState, visibleAlert: true, message: message }));
  };

  const archiveCategory = async () => {
    const categoryRef = doc(firestore, "categories", category?.id);
    await setDoc(
      categoryRef,
      {
        archived: true,
      },
      { merge: true }
    );
    showAlert("Categoria Arquivada com sucesso! :)");
  };

  const reloadPage = () => {
    hideAlert();
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: "Conteudo" }],
      })
    );
  };

  const publishCategory = async () => {
    const categoryRef = doc(firestore, "categories", category?.id);
    await setDoc(
      categoryRef,
      {
        archived: false,
      },
      { merge: true }
    );
    showAlert("Categoria Publicada com sucesso! :)");
  };

  const cards = [
    {
      title: "Nome Card 1",
      img: "",
      description: "",
      order: state.allCards.length + 1,
      archived: true,
    },
  ];

  const addCard = async () => {
    const cardsRef = doc(
      firestore,
      "categories",
      category?.id,
      "cards",
      firestoreAutoId()
    );
    await setDoc(
      cardsRef,
      {
        title: cards[0].title,
        img: cards[0].img,
        description: cards[0].description,
        order: cards[0].order,
        archived: cards[0].archived,
      },
      { merge: true }
    );
    showAlert("Card Adicionado com sucesso! :)");
  };

  const hideAlert = () => {
    setState((prevState) => ({ ...prevState, isDeleteVisible: false }));
  };

  const hideAlertConfirmation = () => {
    setState((prevState) => ({ ...prevState, isConfirmationVisible: false }));
  };

  const yesAlert = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const categoryRef = doc(firestore, "categories", category?.id);
      await deleteDoc(categoryRef);
      setState((prevState) => ({ ...prevState, isConfirmationVisible: true }));
    } catch (error) {
      console.error(error);
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const yesAlert2 = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [{ name: "Conteudo" }],
      })
    );
  };

  const noAlert = () => {
    setState((prevState) => ({ ...prevState, isDeleteVisible: false }));
  };

  return user?.isAdmin ? (
    <ViewPortProvider>
      <Provider>
        <Container>
          <ViewTextInput>
            {state.visible && (
              <>
                <ViewOrder>
                  <TextInput
                    placeholder="Digitar ordem"
                    keyboardType="numeric"
                    returnKeyType="go"
                    value={state.categoryOrder}
                    onChange={({ target: { value } }) => {
                      setState((prevState) => ({
                        ...prevState,
                        categoryOrder: Number(value),
                      }));
                    }}
                  />
                </ViewOrder>
                <ViewText>
                  <TextInput
                    placeholder="Digitar título"
                    keyboardType="default"
                    returnKeyType="go"
                    value={state.categoryTitle}
                    onChange={({ target: { value } }) => {
                      setState((prevState) => ({
                        ...prevState,
                        categoryTitle: value,
                      }));
                    }}
                  />
                </ViewText>
              </>
            )}
            {!state.visible && (
              <>
                <ViewText>
                  <StandardText
                    style={{
                      fontFamily:
                        customFont && customFont.fontFamilyTitle
                          ? removeSansSerif(customFont.fontFamilyTitle)
                          : THEME.FONTFAMILY.BOLD
                    }}
                    color={
                      customColor && customColor.text && customColor.text.app
                        ? customColor.text.app
                        : THEME.COLORS.TITLE_MAIN
                    }
                    textAlign="flex-start"
                    numberOfLines={1}
                    padding="0rem 1rem"
                  >
                    {category?.order}
                  </StandardText>
                </ViewText>
                <ViewText>
                  <StandardText
                    style={{
                      fontFamily:
                        customFont && customFont.fontFamilyTitle
                          ? removeSansSerif(customFont.fontFamilyTitle)
                          : THEME.FONTFAMILY.BOLD
                    }}
                    color={
                      customColor && customColor.text && customColor.text.app
                        ? customColor.text.app
                        : THEME.COLORS.TITLE_MAIN
                    }
                    textAlign="flex-start"
                    numberOfLines={1}
                  >
                    {category?.title}
                  </StandardText>
                </ViewText>
              </>
            )}
            {state.editButtonName === 'edit' && (
              <>
                <ViewText>
                  <ContentIcon
                    onPress={() => {
                      setState((prevState) => ({
                        ...prevState,
                        visible: true,
                        editButtonName: 'save',
                      }));
                    }}
                  >
                    <Feather
                      name="edit-3"
                      size={24}
                      color={
                        customColor && customColor.text && customColor.text.app
                          ? customColor.text.app
                          : THEME.COLORS.TEXT_MAIN
                      }
                    />
                  </ContentIcon>
                </ViewText>
                <ViewText>
                  <ContentIcon onPress={showModal}>
                    <MaterialIcons
                      name="delete-outline"
                      size={24}
                      color={
                        customColor && customColor.text && customColor.text.app
                          ? customColor.text.app
                          : THEME.COLORS.TEXT_MAIN
                      }
                    />
                  </ContentIcon>
                </ViewText>
              </>
            )}
            {state.editButtonName === 'save' && (
              <ViewText>
                <ContentIcon onPress={saveCategories}>
                  <Feather
                    name="save"
                    size={24}
                    color={
                      customColor && customColor.text && customColor.text.app
                        ? customColor.text.app
                        : THEME.COLORS.TEXT_MAIN
                    }
                  />
                  <FooterText margin="0rem 0.5rem">Salvar</FooterText>
                </ContentIcon>
              </ViewText>
            )}
          </ViewTextInput>
          <ViewButton>
            {category?.archived === false && (
              <ContentIcon
                onPress={archiveCategory}
                backgroundColor={THEME.COLORS.BACKGROUND_ARCHIVE}
                style={{ marginRight: '0.5rem' }}
              >
                <Octicons
                  name="archive"
                  size={18}
                  color={THEME.COLORS.TEXT_ARCHIVE}
                />
                <SmallText
                  margin="0rem 0.5rem"
                  color={THEME.COLORS.TEXT_ARCHIVE}
                  style={{
                    fontFamily:
                      customFont && customFont.fontFamilyText
                        ? customFont.fontFamilyText
                        : THEME.FONTFAMILY.REGULAR,
                  }}
                >
                  Arquivar Categoria
                </SmallText>
              </ContentIcon>
            )}
            {category?.archived === true && (
              <ContentIcon
                onPress={publishCategory}
                backgroundColor={THEME.COLORS.BACKGROUND_PUBLISH}
                style={{ marginRight: '0.5rem' }}
              >
                <MaterialIcons
                  name="published-with-changes"
                  size={18}
                  color={THEME.COLORS.TEXT_PUBLISH}
                />
                <SmallText
                  margin="0rem 0.5rem"
                  color={THEME.COLORS.TEXT_PUBLISH}
                  style={{
                    fontFamily:
                      customFont && customFont.fontFamilyText
                        ? customFont.fontFamilyText
                        : THEME.FONTFAMILY.REGULAR,
                  }}
                >
                  Publicar Categoria
                </SmallText>
              </ContentIcon>
            )}
            <ContentIcon
              onPress={addCard}
              backgroundColor={THEME.COLORS.BACKGROUND_PUBLISH}
              style={{ marginRight: '0.5rem' }}
            >
              <Octicons
                name="diff-added"
                size={18}
                color={THEME.COLORS.TEXT_PUBLISH}
              />
              <SmallText
                margin="0rem 0.5rem"
                color={THEME.COLORS.TEXT_PUBLISH}
                style={{
                  fontFamily:
                    customFont && customFont.fontFamilyText
                      ? customFont.fontFamilyText
                      : THEME.FONTFAMILY.REGULAR,
                }}
              >
                Novo Card
              </SmallText>
            </ContentIcon>
          </ViewButton>
          <Content>
            <MobileOrDesktopLeftArrow array={data} />
            <FlatList
              data={data}
              style={{ marginLeft: state.scrollX, paddingLeft: '1rem' }}
              renderItem={({ item }) => (
                <CategoryItem
                  categoryId={category?.id}
                  item={item}
                  visible={state.isSearchVisible}
                  modalFunction={modalFunction}
                  user={user ? user : undefined}
                />
              )}
              horizontal
              showsHorizontalScrollIndicator={false}
            />
            <MobileOrDesktopRightArrow array={data} />
          </Content>
          <AlertBox
            title={
              'Você TEM CERTEZA que deseja excluir essa categoria inteira?'
            }
            message={
              'Ao excluir, todos os conteúdos correspondentes aos cards contidos nela serão removidos permanentemente (isso inclui todos os vídeos, áudios, PDFs e imagens de capa).'
            }
            visible={state.isDeleteVisible}
            leftButton={'Sim'}
            rightButton={'Não'}
            leftButtonFunction={yesAlert}
            rightButtonFunction={noAlert}
            onClose={hideAlert}
          ></AlertBox>
          <AlertBox
            message={'Categoria excluída com sucesso!!'}
            visible={state.isConfirmationVisible}
            leftButton={'OK'}
            leftButtonFunction={yesAlert2}
            onClose={hideAlertConfirmation}
          ></AlertBox>
          {state.visibleAlert && (
            <AlertBox
              message={state.message}
              visible={state.visibleAlert}
              onClose={hideAlert}
              leftButtonFunction={reloadPage}
              leftButton={'OK'}
            ></AlertBox>
          )}
          {state.isLoading && (
            <ActivityIndicator
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              color={
                customColor && customColor.primary
                  ? customColor.primary
                  : THEME.COLORS.PRIMARY_800
              }
            />
          )}
        </Container>
      </Provider>
    </ViewPortProvider>
  ) : (
    <ViewPortProvider>
      <Container>
        <StandardText
          fontSize={
            customFont && customFont.fontMD
              ? customFont.fontMD
              : THEME.FONTSIZE.MEDIUM
          }
          style={{
            fontFamily:
              customFont && customFont.fontFamilyTitle
                ? customFont.fontFamilyTitle
                : THEME.FONTFAMILY.BOLD,
          }}
          color={
            customColor && customColor.text && customColor.text.app
              ? customColor.text.app
              : THEME.COLORS.TITLE_MAIN
          }
          textAlign="left"
          numberOfLines={1}
          margin="0rem 0rem 1rem 1rem"
        >
          {title}
        </StandardText>
        <Content>
          <MobileOrDesktopLeftArrow array={data} />
          <FlatList
            data={data}
            style={{ marginLeft: state.scrollX, paddingLeft: '1rem' }}
            renderItem={({ item }) => (
              <CategoryItem categoryId={category?.id} item={item} user={user} />
            )}
            horizontal
            showsHorizontalScrollIndicator={false}
          />
          <MobileOrDesktopRightArrow array={data} />
        </Content>
      </Container>
    </ViewPortProvider>
  );
};

export default CategoryList;
